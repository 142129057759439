import { discourse_redirect } from '@/utils/discourse_auth'
import { tradly_integration_redirect } from '@/utils/tradly_integration_auth'
import axios from 'axios'
import { encryptDataWithAES } from 'constant/functions'
import {
  MixpanelEventName,
  MixpanelIdentifyCall,
  MixpanelPropertiesName,
  MixpanelTracking,
} from 'constant/mixpnael'
import {
  auth_cookie_name,
  cookies_domain_name,
  global_auth_cookie_name,
  refresh_cookie_name,
  tenant_cookie_name,
  uuid_cookie_name,
} from 'constant/url'
import { deleteCookie, setCookie } from 'cookies-next'
import { uuid } from 'uuidv4'
import { sendVerificationLink } from './SendVerificationLink'

export const sign_in_handler = async ({
  setIsLoading,
  data,
  is_log_in_by_discourse,
  is_log_in_by_tradly_integration,
  redirect_url,
  sso,
  sig,
  router,
  setOpenAlert,
  setAlert_type,
  setMessage,
}) => {
  setIsLoading(true)
  axios
    .post('/api/login', {
      user: data,
    })
    .then((res) => {
      // console.log(res)
      axios
        .get('/api/user_tenants_details', {
          params: { auth_key: res.data.user.key.auth_key },
        })
        .then((res2) => {
          deleteCookie(auth_cookie_name)
          deleteCookie(refresh_cookie_name)
          deleteCookie(uuid_cookie_name)
          deleteCookie(tenant_cookie_name)
          deleteCookie(global_auth_cookie_name)

          // cookie setting
          if (!res.data.user.email_verified) {
            setCookie(global_auth_cookie_name, encryptDataWithAES(JSON.stringify(res.data.user)), {
              maxAge: 86400,
              domain: cookies_domain_name,
              path: '/',
            })
          } else {
            setCookie(auth_cookie_name, encryptDataWithAES(res.data.user.key.auth_key), {
              maxAge: 860400,
              domain: cookies_domain_name,
              path: '/',
            })
            setCookie(uuid_cookie_name, encryptDataWithAES(uuid()), {
              maxAge: 860400,
              domain: cookies_domain_name,
              path: '/',
            })
            setCookie(refresh_cookie_name, encryptDataWithAES(res.data.user.key.refresh_key), {
              maxAge: 860400,
              domain: cookies_domain_name,
              path: '/',
            })
          }

          // mixpanel call
          MixpanelIdentifyCall(res.data.user?.email)

          MixpanelTracking(MixpanelEventName.sign_in, {
            [MixpanelPropertiesName.user_name]: res.data.user.name,
            [MixpanelPropertiesName.user_email]: res.data.user?.email,
          })

          //
          if (
            res2.data.data.tenants?.length > 1 &&
            !is_log_in_by_discourse &&
            !is_log_in_by_tradly_integration
          ) {
            setCookie(
              tenant_cookie_name,
              encryptDataWithAES(res2.data.data.tenants?.filter((tenant) => tenant?.default)[0].id),
              {
                maxAge: 860400,
                domain: cookies_domain_name,
                path: '/',
              }
            )
            if (!res.data.user.email_verified) {
              axios
                .get(`/api/auth/verification_link?auth_key=${res.data.user.key.auth_key}`)
                .then((res) => {
                  router.push(`/verify_email?to=${redirect_url}`)
                })
            } else {
              router.push(`/tenants?to=${redirect_url}`)
            }
          } else {
            if (is_log_in_by_discourse) {
              discourse_redirect({ sso, sig, router, user: res.data.user })
            } else if (is_log_in_by_tradly_integration) {
              tradly_integration_redirect({ sso, sig, router, user: res.data.user })
            } else {
              if (!res.data.user.email_verified) {
                axios
                  .get(`/api/auth/verification_link?auth_key=${res.data.user.key.auth_key}`)
                  .then((res) => {
                    router.push(`/verify_email?to=${redirect_url}`)
                  })
              } else {
                router.push(redirect_url)
              }
            }
          }
        })
      setIsLoading(false)
    })
    .catch((err) => {
      const message = err?.response?.data?.message
      setIsLoading(false)
      if (message) {
        setOpenAlert(true)
        setAlert_type('error')
        setMessage(message)
      } else {
        if (message) {
          setOpenAlert(true)
          setAlert_type('error')
          setMessage('Something is wrong , please try latter')
        }
      }
    })
}
